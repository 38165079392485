.page-nav-top {
    position: relative;
    height: 46px;
    z-index: (@zindex-navbar + 1); // One more than the bottom one
    transition: transform .4s;
    background: @brand-primary;

    .page-nav-social-media {
        float: left;
        margin-left: -15px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline;

                a {
                    display: block;
                    float: left;
                    padding: 13px 10px;
                    color: @top-nav-text-color;
                }
            }
        }
    }

    .page-nav-title {
        position: absolute;
        top: 0;
        left: 50%;
        padding: 15px 0;
        display: block;
        color: @top-nav-text-color;
        font-size: @font-size-small;
        text-transform: uppercase;
        transform: translate(-50%, 0);

        @media (max-width: @screen-xs-max) {
            display: none;
        }
    }

    .page-nav-dropdowns {
        float: right;
        margin-right: -15px;

        .page-nav-user,
        .page-nav-language {
            position: relative;
            float: left;


            &.open > a {
                background: lighten(@brand-dark, 7%);
                color: @white;
            }

            > a {
                display: block;
                color: @top-nav-text-color;
                text-transform: uppercase;
                padding: 13px 10px;

                &:hover, &:focus {
                    text-decoration: none;
                }

                @media (max-width: @screen-xs-max) {
                    i:first-child {
                        margin-right: 5px;
                    }
                }

                > span {
                    font-size: @font-size-small;
                    margin: 0px 5px;
                }
            }
        }

        .dropdown-menu {
            left: auto;
            right: 0;
            margin-top: 0;
            border: 0;
            border-radius: 0;
            color: @top-nav-text-color;
            background: lighten(@brand-dark, 7%);
            box-shadow: none;
            color: @white;
        }
    }

    .page-nav-user {
        .dropdown-menu {
            padding: 15px;
            width: 280px;

            .form-control {
                box-shadow: none;
                border-color: rgba(0, 0, 0, 0.3);
                background: rgba(255, 255, 255, 0.1);
                color: @white;
            }

            > a {
                display: block;
                padding: 5px 0;
                color: @white;
            }
        }
    }

    .page-nav-language {
        .dropdown-menu {
            padding: 0;

            .active > a {
                background: transparent;
                color: @link-color;

                &:hover, &:focus {
                    background: rgba(255, 255, 255, 0.1);
                    color: @white;
                }
            }

            li > a {
                padding: 10px 15px;
                color: @white;

                &:hover, &:focus {
                    background: rgba(255, 255, 255, 0.1);
                    color: @white;
                }
            }
        }
    }
}

.page-nav-bottom {
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    z-index: @zindex-navbar;
    background: @brand-dark;
    transition: height .2s;

    html.cms-toolbar-expanded & {
        top: (46px + 46px);
    }

    @media (min-width: @screen-md-min) {
        body.nav-sticky & {
            position: fixed;
            top: 0;
        }

        html.cms-toolbar-expanded body.nav-sticky & {
            top: 46px;
        }
    }

    .page-nav-logo {
        float: left;
        margin-left: -15px;

        a {
            display: block;
            padding: 10px 15px;
            transition: all .2s;

            @media (min-width: @screen-md-min) {
                padding: 15px;

                body.nav-sticky & {
                    padding: 10px 15px;
                }
            }

            img {
                height: 50px;
                transition: all .2s;

                @media (min-width: @screen-md-min) {
                    height: 60px;

                    body.nav-sticky & {
                        height: 50px;
                    }
                }
            }
        }
    }

    .page-nav-pages {
        float: right;
        margin-right: -20px;

        @media (max-width: @screen-sm-max) {
            opacity: 0;
            z-index: -999;
            visibility: hidden;
            position: absolute;
            float: none;
            top: 100%;
            left: 0;
            right: 0;
            background: @brand-darker;
            transition: all .2s;
            overflow: auto;
            margin: 0;

            body.mobile-nav-open & {
                opacity: 1;
                visibility: visible;
                z-index: 2000;
            }
        }

        > ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li a {
                font-size: 13px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li a {
                    font-size: 12px;
                    padding: 10px 15px;
                }

                @media (min-width: @screen-md-min) {
                    display: none;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background: lighten(@brand-dark, 5%);
                    width: 220px;
                    text-align: right;

                    li a {
                        display: block;
                        width: 100%;
                        padding: 15px 20px;
                    }
                }

                li > ul {
                    display: none;
                }
            }

            li.selected {
                > a {
                    color: @brand-primary;
                }
            }

            > li {
                display: block;
                float: left;
                position: relative;
                padding: 15px 0;
                transition: all .15s;

                @media (max-width: @screen-sm-max) {
                    padding: 0;
                    float: none;
                    text-align: center;
                    border-top: 2px solid @brand-dark;
                }

                @media (min-width: @screen-md-min) {
                    &:hover, &:focus {
                        background: lighten(@brand-dark, 5%);

                        > ul {
                            display: block;
                        }
                    }
                }

                a {
                    display: block;
                    float: left;
                    color: @white;
                    font-weight: normal;
                    padding: 20px 15px;
                    text-transform: uppercase;
                    transition: all .2s;

                    @media (min-width: @screen-md-min) {
                        body.nav-sticky & {
                            padding: 10px 15px;
                        }
                    }

                    @media (max-width: @screen-sm-max) {
                        float: none;
                    }

                    &:hover, &:focus {
                        text-decoration: none;
                        color: @link-color;
                    }
                }
            }
        }
    }

    .mobile-nav-toggler {
        display: block;
        float: right;
        position: relative;
        height: 70px;
        width: 70px;
        border: 0;
        padding: 0;
        background: transparent;
        outline: 0;
        margin-right: -15px;

        @media (min-width: @screen-md-min) {
            display: none;
        }

        &:hover {
            .hamburger,
            .hamburger:before,
            .hamburger:after {
                background: @link-color;
            }
        }

        body.mobile-nav-open & .hamburger {
            background-color: transparent;

            &:before, &:after {
                top: 0;
                background: @link-color;
            }

            &:before {
                transform: rotate(-45deg);
            }

            &:after {
                transform: rotate(45deg);
            }
        }

        .hamburger,
        .hamburger:before,
        .hamburger:after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 22px;
            height: 3px;
            margin-top: -2px;
            margin-left: -11px;
            background: @white;
            border-radius: 1px;
            cursor: pointer;
            transition: all .4s;
        }

        .hamburger:before {
            top: -6px;
            margin-top: 0;
        }

        .hamburger:after {
            top: 6px;
            margin-top: 0;
        }
    }

}
