.btn {
    text-transform: uppercase;
    font-size: 1.4rem;
    transition: all .15s;
    outline: 0 !important;
}

.btn-primary,
.btn-info,
.btn-warning,
.btn-danger {
    border-color: transparent;

    &:hover, &:focus, &:active {
        border-color: transparent;
    }
}

.btn-primary {
    color: @brand-dark;

    &:hover, &:focus, &:active {
        color: @brand-dark;
    }

    &.btn-outline {
        background: transparent;
        color: @brand-primary;
        border: 2px solid @brand-primary;

        &:hover, &:focus, &:active {
            background: @brand-primary;
            color: @brand-dark;
        }
    }
}

.btn-white {
    background-color: @white;
    color: @brand-dark;
    border-color: transparent;

    &:hover, &:focus, &:active {
        background-color: @brand-primary;
    }

    &.btn-outline {
        background-color: transparent;
        border: 2px solid @white;
        color: @white;

        &:hover, &:focus, &:active {
            background-color: @white;
            color: @brand-dark;
        }
    }
}

.btn-dark {
    background-color: @brand-dark;
    color: @brand-dark;
    border-color: transparent;

    &:hover, &:focus, &:active {
        background-color: darken(@brand-dark, 5%);
    }

    &.btn-outline {
        background-color: transparent;
        border: 2px solid @brand-dark;
        color: @brand-dark;

        &:hover, &:focus, &:active {
            background-color: @brand-dark;
            color: @white;
        }
    }
}

.scroll-down-button {
    .btn;
    .btn-primary;
    .btn-outline;
    width: 45px;
    height: 45px;
    padding: 0;
    line-height: 1.45;
    text-align: center;
    border-radius: 50%;
    font-size: 30px;
}
