html {
    font-size: 62.5%;
}

body {
    background: @brand-dark;
    font-weight: 300;
}

.content-wrap {
    position: relative;
    padding-top: 70px;
}

.lead {
    @media (min-width: @screen-sm-min) {
        font-size: 18px;
    }
}

label {
    font-weight: normal;
}

.filer_image_info {
    margin: 10px 0;
    display: inline-block;
}
