.news-lift-container {
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: @screen-sm-min) {
        display: flex;
        flex-flow: column wrap;
    }

    @media (min-width: @screen-md-min) {
        flex-flow: row wrap;
    }
}

.news-lift {
    padding: 15px;

    @media (min-width: @screen-sm-min) {
        display: flex;
        flex: 0 0 auto;
        flex-basis: 100%;
    }

    @media (min-width: @screen-md-min) {
        flex-basis: percentage(6 / 12, 5);
    }

    .news-lift-container.three-columns & {
        @media (min-width: @screen-md-min) {
            flex-basis: percentage(4 / 12, 5);
        }
    }

    a {
        color: @white;
        transition: all .15s;

        &:hover, &:focus {
            text-decoration: none;
            color: @link-color;
        }
    }

    .news-lift-details {
        width: 100%;
        background: rgba(255, 255, 255, .05);

        @media (min-width: @screen-sm-min) {
            display: flex;
            flex-flow: column nowrap;
        }

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            flex-flow: row nowrap;
        }
    }

    .news-lift-image-wrap {
        flex: 0 0 auto;
        background: @white;

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            flex-basis: 50%;
        }

        a {
            &:hover, &:focus {
                img {
                    opacity: 0.9;
                }
            }
        }

        img {
            transition: all .15s;
        }
    }

    .news-lift-text {
        padding: 30px 15px;

        @media (min-width: @screen-sm-min) {
            padding: 30px;
            flex: 1 auto;
            flex-basis: 50%;
        }

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            align-self: center;
        }
    }

    .news-lift-date {
        opacity: 0.75;
        margin-bottom: 10px;
        font-size: @font-size-small;
    }

    .news-lift-title {
        font-size: @font-size-large;
        transition: all .15s;
    }

    .news-lift-tags {
        margin-top: 25px;
    }
}

.tag-label {
    display: inline-block;
    margin: 0 5px 7px 0;
    padding: .2em .6em;
    font-weight: 300;
    background: rgba(255, 255, 255, 0.1);
    border-radius: @border-radius-small;
    color: fade(@white, 80%);
    font-size: 75%;
    transition: all .15s;

    &:hover, &:focus, &:active {
        color: @link-color;
        text-decoration: none;
        background: rgba(255, 255, 255, 0.15);
    }
}

.article-detail {
    .author {
        margin-right: 15px;
    }

    .author,
    .date {
        opacity: 0.75;

        i {
            margin-right: 3px;
            color: @brand-primary;
        }
    }
}
