.single-image-feature {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    margin-top: 30px;
    text-align: center;

    &:nth-child(1) {
        margin-top: 0;
    }

    @media (min-width: @screen-sm-min) {
        text-align: left;
        padding-left: 95px;
        padding-right: 15px;
    }

    img {
        max-width: 65px;
        width: 100%;
        height: auto;
        margin-bottom: 15px;

        @media (min-width: @screen-sm-min) {
            position: absolute;
            left: 15px;
            top: 0;
        }
    }

    .single-feature-title {
        margin-top: 0;
        font-size: @font-size-base;
    }

    .single-feature-description {
        color: @text-muted;
        font-size: @font-size-base;
    }
}
