.single-some-lift {
    display: block;
    position: relative;
    padding: 0px 0px 0px 40px;
    text-align: left;

    @media (min-width: 500px) {
        padding: 0px 15px 0px 40px;
    }

    &.twitter i:before {
        content: @fa-var-twitter;
    }

    &.facebook i:before {
        content: @fa-var-facebook;
    }

    .some-lift-icon {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2rem;
        width: 30px;
        text-align: center;
        opacity: 0.75;
        transition: all .15s;

        &:hover, &:focus {
            opacity: 1;
            color: @link-color;
            text-decoration: none;
        }
    }

    .some-lift-text {
        transition: all .15s;
        display: block;

        @media (min-width: 500px) {
            padding-right: 30px;
        }

        &:hover, &:focus {
            color: @link-color;
            text-decoration: none;
        }
    }

    .timestamp {
        margin-top: 10px;
        display: block;
        font-size: @font-size-small;
        transition: all .15s;
        opacity: 0.75;
    }
}
