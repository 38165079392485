footer.top {
    padding: 60px 0px;
    color: @brand-dark;
    background: @footer-bg;
    position: relative;

    @media (min-width: @screen-sm-min) {
        padding: 100px 0;
    }

    a:not(.btn) {
        color: @brand-dark;
        transition: all .15s;

        &:hover, &:focus {
            text-decoration: none;
            color: darken(@brand-dark, 5%);
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;

            @media (min-width: @screen-md-min) {
                display: block;
            }

            a {
                padding: 0 15px 10px 0;
                display: block;
            }
        }
    }

    h2, h3 {
        font-size: @font-size-h3;
        margin: 20px 0;

        @media (min-width: @screen-md-min) {
            margin: 0 0 20px 0;
        }
    }

    input[type="text"],
    input[type="email"],
    textarea {
        border: 2px solid @brand-dark;
        background: transparent;
        width: 100%;
        padding: 6px 12px;
        color: @brand-dark;
        margin-bottom: 10px;
    }

    button[type="submit"] {
        .btn;
        .btn-dark;
        .btn-outline;
    }

    .container {
        @media (max-width: @screen-xs-max) {
            padding-left: 45px;
            padding-right: 45px;
        }
    }

    .col-md-4 {
        padding-left: 30px;
        padding-right: 30px;

        & + .col-md-4 {
            @media (max-width: @screen-sm-max) {
                margin-top: 30px;
            }
        }
    }

    .footer-logo {
        margin-bottom: 45px;

        img {
            width: 100%;
            max-width: 250px;
        }
    }

    .footer-some {
        i {
            font-size: 120%;
            margin-right: 2px;
        }
    }

    .footer-placeholder + .footer-placeholder {
        margin-top: 30px;
    }

    .scroll-to-top-button {
        .btn;
        .btn-dark;
        .btn-outline;
        position: absolute;
        bottom: 50px;
        right: 50px;
        width: 45px;
        height: 45px;
        padding: 0;
        line-height: 1.3;
        text-align: center;
        border-radius: 50%;
        font-size: 30px;

        @media (max-width: @screen-sm-max) {
            display: none;
        }

        &:hover, &:focus {
            color: @white !important;
        }
    }

    .padded-left-content {
        @media (min-width: @screen-md-min) {
            padding-left: 50px;
        }
    }
}

footer.bottom {
    height: 45px;
    line-height: 45px;
    color: @brand-dark;
    font-size: 12px;
    text-align: center;
    background: darken(@footer-bg, 2%);
}
