section {
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width: @screen-md-min) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &.no-padding-top {
        padding-top: 0;
    }

    &.no-padding-bottom {
        padding-bottom: 0;
    }

    &.small-padding-top {
        padding-top: 20px;

        @media (min-width: @screen-md-min) {
            padding-top: 30px;
        }
    }

    &.small-padding-bottom {
        padding-bottom: 20px;

        @media (min-width: @screen-md-min) {
            padding-bottom: 30px;
        }
    }

    &.medium-padding-top {
        padding-top: 40px;

        @media (min-width: @screen-md-min) {
            padding-top: 60px;
        }
    }

    &.medium-padding-bottom {
        padding-bottom: 40px;

        @media (min-width: @screen-md-min) {
            padding-bottom: 60px;
        }
    }

    &.bg-image-section,
    &.single-column-section,
    &.two-column-section,
    &.three-column-section {
        color: @white;

        .section-content-padding {
            padding: 30px 0px;

            @media (min-width: @screen-md-min) {
                padding: 60px 0px;
            }
        }

        .section-title {
            margin: 0 0 45px 0;
            font-size: @font-size-h3;
            font-weight: bold;
        }

        .section-buttons {
            margin-top: 35px;
            margin-left: -5px;

            .btn {
                margin: 10px 5px 0px 5px;
            }
        }

        .scroll-down-button {
            margin-top: 30px;
        }

        .single-some-lift {
            a {
                color: @white;
            }
        }

        &.dark-text {
            color: @brand-dark;

            .scroll-down-button {
                .btn-dark;
                .btn-outline;
            }

            .btn-primary {
                .btn-dark;
            }

            .btn-primary.btn-outline {
                .btn-dark;
                .btn-outline;
            }

            .single-some-lift {
                a {
                    color: @brand-dark;
                }
            }

            .owl-carousel .owl-dots .owl-dot {
                span {
                    background: #000 !important;
                }
            }
            input[type="text"],
            input[type="email"],
            textarea{
                color: @brand-dark;
            }
        }
    }
}

.bg-image-section {
    position: relative;
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        background: @brand-dark;
    }

    > .container {
        position: relative;
        z-index: 2;
    }
}

.two-column-section {
    .column {
        &:nth-child(2n+1) {
            clear: both;
        }

        &:nth-child(n+2) {
            margin-top: 45px;

            @media (min-width: @screen-md-min) {
                margin: 0;
            }
        }

        &:nth-child(n+3) {
            @media (min-width: @screen-md-min) {
                margin-top: 45px;
            }
        }
    }
}

.three-column-section {
    .column {
        &:nth-child(3n+1) {
            clear: both;
        }

        &:nth-child(n+2) {
            margin-top: 45px;

            @media (min-width: @screen-md-min) {
                margin: 0;
            }
        }

        &:nth-child(n+4) {
            @media (min-width: @screen-md-min) {
                margin-top: 45px;
            }
        }
    }
}
