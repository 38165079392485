.single-feature {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    margin-top: 30px;
    text-align: center;

    &:nth-child(1) {
        margin-top: 0;
    }

    @media (min-width: @screen-sm-min) {
        text-align: left;
        padding-left: 5rem;
        padding-right: 15px;
    }

    .single-feature-title {
        margin-top: 0;
        font-size: @font-size-base;
    }

    .single-feature-description {
        color: @text-muted;
        font-size: @font-size-base;
    }

    .single-feature-icon {
        @media (min-width: @screen-sm-min) {
            position: absolute;
            left: 15px;
            top: 0;

            i {
                font-size: 2rem;
            }
        }

        &.icon-color-primary {
            color: @brand-primary;
        }

        &.icon-color-pink {
            color: @brand-pink;
        }

        &.icon-color-info {
            color: @brand-info;
        }

        &.icon-color-success {
            color: @brand-success;
        }

        &.icon-color-warning {
            color: @brand-warning;
        }

        &.icon-color-danger {
            color: @brand-danger;
        }

        i {
            font-size: 3rem;
            margin-bottom: 10px;
        }
    }
}
