.page-hero {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/hero.jpg);
    color: @white;
    position: relative;

    &:before {
        content: "";
        background-color: @brand-dark;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.4;
        z-index: 1;
    }

    &.dynamic {
        .container {
            position: relative;
            z-index: 2;
            padding-top: 155px;
            padding-bottom: 125px;

            @media (min-width: @screen-md-min) {
                padding-top: 240px;
                padding-bottom: 210px;
            }
        }
    }

    &.full-page {
        .absolute-container {
            position: relative;
            z-index: 2;

            @media (min-width: @screen-md-min) {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .hero-content {
            padding-top: 125px;
            padding-bottom: 75px;

            @media (min-width: @screen-md-min) {
                padding-top: 100px;
                padding-bottom: 50px;
            }
        }
    }

    .hero-content {
        text-align: center;
    }

    .breadcrumb-container {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 30px;
        z-index: 2;

        @media (max-width: @screen-xs-max) {
            text-align: center;
        }

        .breadcrumb {
            color: @white;
            padding: 0;
            margin: 15px 0 0 0;
            background: transparent;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

            a {
                color: @white;
            }

            > li + li:before {
                content: @fa-var-angle-double-right;
                font-family: FontAwesome;
                color: @white;
                opacity: 0.75;
            }

            li.active {
                color: @white;
                opacity: 0.75;
            }
        }
    }

    h1 {
        .h2();
        margin: 0 0 30px 0;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

        @media (min-width: @screen-sm-min) {
            .h1();
            margin: 0 0 30px 0;
        }
    }

    .btn {
        margin-top: 30px;
    }
}
