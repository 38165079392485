.carousel {
    position: relative;

    &.fade {
        opacity: 1;

        .carousel-inner {
            position: relative;
            z-index: 1;
            height: 500px;
        }

        .item {
            display: block !important;
            position: absolute;
            left: 0 !important;
            top: 0;
            right: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            z-index: 1;
            opacity: 0;
            transition: opacity 1.2s ease-in-out;

            &.active {
                z-index: 2;
                opacity: 1;
                transition: opacity 1.2s ease-in-out;
            }

            > .container {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                margin-top: -20px;
            }
        }
    }

    .carousel-text {
        color: @white;
        display: block;
        align-self: center;
        padding: 100px 0;

        @media (max-width: @screen-sm-max) {
            text-align: center;
        }

        .carousel-title {
            text-transform: uppercase;

            .h2();
            margin: 0;

            @media (min-width: @screen-sm-min) {
                .h1();
                margin: 18px 0;
            }
        }

        .carousel-link {
            margin-top: 30px;

            @media (min-width: @screen-sm-min) {
                margin-top: 70px;
            }

            .btn {
                padding: 15px 30px;
            }
        }
    }

    .carousel-description {
        font-size: 18px;
    }

    .carousel-controls {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-flow: row nowrap;
        z-index: 2;

        > .container {
            display: none;

            @media (min-width: @screen-md-min) {
                display: block;
            }
        }

        .carousel-slide-preview {
            position: relative;
            display: flex;
            justify-content: center;

            .active {
                background-color: transparent;
            }

            a {
                display: block;
                width: 14px;
                height: 14px;
                margin: 0 10px;
                text-indent: -9999px;
                background-color: transparent;
                border: 1px solid @brand-primary;
                border-radius: 20px;

                &:hover, &:focus {
                    cursor: pointer;
                    opacity: 1;
                }

                &.active {
                    background-color: @brand-primary;
                    opacity: 1;
                }
            }
        }
    }

    .carousel-control-buttons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        width: 100%;

        .carousel-control-button {
            font-size: 40px;
            text-align: center;
            color: @brand-primary;
            cursor: pointer;
            margin: 0 15px;
            transition: all .15s;
            opacity: 0.7;

            &:hover, &:focus {
                opacity: 1;
                text-decoration: none;
            }

            @media (min-width: @screen-md-min) {
                line-height: 90px;
                margin: 0 40px;
            }
        }
    }
}
