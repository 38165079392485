.owl-carousel {
    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        margin-top: 30px;
    }

    .owl-dot {
        display: inline-block;

        &:hover, &:focus {
            span {
                background: @white;
                opacity: 1;
            }
        }

        &.active span {
            background: @white;
            opacity: 1;
        }

        span {
            width: 10px;
            height: 10px;
            margin: 5px 7px;
            background: @white;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity .3s;
            border-radius: 50%;
            opacity: 0.5;
        }
    }
}
